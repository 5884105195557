import {Component} from 'react'
import React from 'react'
import Map from "./Map"
import axios from "axios"
//import ic from "node-icecream";
import {Route} from './Route';
import Tsp from './Tsp';
import Intro from "./Intro";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import Globals from "./Globals";
import {Link} from "react-router-dom";
import Navbar from "./Navbar";
import eventBus from "./EventBus";
import './i18n';
import { Trans } from 'react-i18next';
import i18n from "i18next";

class Editor extends Component {
    // BASE_URL = 'https://localhost:3001/artmap/server/index.php'
    DOT_CLICK_RADIUS = 0.02 * 2
    BASE_URL = 'https://bcnartmap.local:3001/artmap/server/index.php'
    toastTimeout = null
    routeUpdatedTimeout = null
    routeUpdatedLastTime = null

    festivalsInfo = [
        {
            'id': 'EVTID003',
            'title': 'BARCELONA GALLERY WEEKEND',
            'image': '/galleries/bgw.svg',
            'description': <div>15 Sept. - 18 Sept. 2022</div>,
            'website': 'https://www.barcelonagalleryweekend.com/'
        },
        {
            'id': 'EVTID002',
            'title': 'SWAB BARCELONA',
            'image': 'https://swab.es/wp-content/uploads/2019/11/swab-logo.png',
            'description': <div>Contemporary art fair<br />Oct 7th - Oct 10th 2021</div>,
            'website': 'https://swab.es/'
        },
        {
            'id': 'EVTID001',
            'title': 'TALLERS OBERTS POBLENOU 2021',
            'image': 'https://jimdo-storage.freetls.fastly.net/image/228022728/698ed6b7-29d2-4f47-b29b-4a17c40b90ae.jpg?format=pjpg&quality=80&auto=webp&disable=upscale&width=922&height=1152&trim=0,0,0,0',
            'description': <div>Workshops open doors, Poblenou area<br />Sept 17th - Sept 19th 2021</div>,
            'website': 'http://www.tallersobertspoblenou.com'
        },
        {
            'id': '',
            'title': 'All galleries',
            'image': '',
            'description':<div></div>,
            'website': ''
        },
    ];

    constructor(props) {
        super(props)

        this.addPointOnMap = this.addPointOnMap.bind(this)
        this.handleStartEditing = this.handleStartEditing.bind(this)
        this.onInputchange = this.onInputchange.bind(this)
        this.onInputchangeFestival = this.onInputchangeFestival.bind(this)
        this.onSubmitForm = this.onSubmitForm.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.onCheckboxEventChange = this.onCheckboxEventChange.bind(this)

        let pathCoos = []

        this.state = {
            pathCoordinates: pathCoos,
            routePoints: [],
            currentRouteHash: '',
            currentRouteQrImage: null,
            coos: [],
            selectedCoos: {lat: null, lng: null},
            editingEnabled: false,
            editingPoint: null,
            hoverPoint: null,

            edit_placename: '',
            edit_image: '',
            edit_website: '',
            edit_color: '',
            edit_address: '',
            edit_schedule: '',
            edit_events: '',
            edit_delete: false,

            filterEvent: this.festivalsInfo[0].id,

            showToast: false,
            toastMessage: '',
            toastIsError: false,

            infoPanelOpen: false,
            routePanelOpen: false,
            sharePanelOpen: false,
            iframePanelOpen: false,
            iframePanelInformationOpen: false,
            iframePanelInformation2Open: false,
            welcomePanelOpen: false,
            eventsInfoPanelOpen: Globals.debugMode ? false : false,

            childQuitEditingMode: null,
            preparedEditMode: false,

            selectedFestival: this.festivalsInfo[0],

            isMoving: false,

            mapCenter: {
                lat: 41.39474690000001,
                lng: 2.172483800000009
            },

            iframeUrl: null,

            walkedDistanceText: '',
        }

        console.log('Editor mount')
        eventBus.on("suggestionSelected", (data) => {
            console.log('Received message')
            // console.log(data)
            // console.log(this.state.coos)
            // this.openToast('Lugar seleccionado')
            this.showInfoPanel2(data.message.coos)
            // this.setState({infoPanelOpen: true})
            // let position = data.message.coos
            // const foundInArray = this.state.coos.findIndex(e => e.coos.lat === position.lat && e.coos.lng === position.lng)
            // if (foundInArray !== -1) {
            //     let point = this.state.coos[foundInArray]
            //     point.hasExternalWebsiteIcon = point.website !== null && point.website.indexOf('https') === 0 ? '' : '↗';
            //     this.setState({routePanelOpen: false, infoPanelOpen: true, hoverPoint: point,
            //         mapCenter: {lat: point.coos.lat, lng: point.coos.lng}
            //     })
            // }
        });

        // this.displayWalkedDistance()
    }

    componentWillUnmount() {
            console.log('Editor unmount')
            eventBus.remove('suggestionSelected', () => {})
    }

    updateRouteOrder(newPathCoosStr) {
        let newPathCoos = newPathCoosStr.map((e) => {
            return {lat: parseFloat(e.split(';')[0]), lng: parseFloat(e.split(';')[1])}
        })
        // console.log(newPathCoos)
        this.saveToServer(newPathCoos)
        // this.openToast('Route order updated')

        this.displayWalkedDistance()
    }

    async saveToServer(newPathCoos) {
        const url = this.BASE_URL + "?action=saveroute"
        // const url = "http://bcnartmap.local/artmap/server/index.php"
        const api = axios.create({
            baseURL: url
        });

        console.log('saving route')
        const response = await api.post(url, {
            content: JSON.stringify(newPathCoos),
            hash: localStorage['artmap-routehash']
        });
        try {
            localStorage['artmap-routehash'] = response.data.hash
            this.setState({currentRouteHash: response.data.hash})
            window.history.pushState({}, '', '?hash=' + localStorage['artmap-routehash']);
            this.loadRoute()
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    componentDidMount() {
        console.log('debugMode: ' + Globals.debugMode)

        if(getCookieConsentValue() === 'false') {
            resetCookieConsentValue()
            window.location.reload()
        }

        this.loadAll()

        // Show welcome panel
        if(!Globals.debugMode) {
            if(localStorage['welcomePanel'] === undefined) {
                console.log('Showing welcome panel')
                localStorage['welcomePanel'] = new Date().getTime()
                this.setState({welcomePanelOpen: true})
            }
            else {
                if(new Date().getTime() - parseInt(localStorage['welcomePanel']) > 120 * 1000) {
                    console.log('Showing welcome panel')
                    this.setState({welcomePanelOpen: true})
                }
                else {
                    console.log('Not showing welcome panel')
                }

                localStorage['welcomePanel'] = new Date().getTime()
            }
        }
    }

    async loadAll() {
        // await this.loadRoute()
        await this.loadPoints()
        await this.checkRouteUpdated()
        //this.randomAddToRoute()
    }

    async checkRouteUpdated() {
        if (localStorage['artmap-routehash'] !== undefined && localStorage['artmap-routehash'] !== 'undefined') {
            console.log('checking')
            const url = this.BASE_URL + "?action=updated&routehash=" + localStorage['artmap-routehash']
            const response = await axios.get(url);

            console.log('this.routeUpdatedLastTime: ' + JSON.stringify(this.routeUpdatedLastTime))
            console.log('response.data: ' + JSON.stringify(response.data))

            if (this.routeUpdatedLastTime === null || response.data.timestamp > this.routeUpdatedLastTime.timestamp) {
                console.log('requires update')
                await this.loadRoute()
            }

            this.routeUpdatedLastTime = response.data
        } else {
            // No route yet, create one
            await this.loadRoute()
        }

        if(!Globals.debugMode) {
            this.routeUpdatedTimeout = window.setTimeout(() => {
                this.checkRouteUpdated()
            }, 2000)
        }
    }

    async loadRoute() {
        console.log('loadRoute')
        // Read from get param
        if (window.location.search.indexOf('?hash=') === 0) {
            var readRouteHash = window.location.search.replace('?hash=', '')
            if(readRouteHash !== 'undefined') {
                localStorage['artmap-routehash'] = readRouteHash
                this.setState({currentRouteHash: localStorage['artmap-routehash']})
            }
            else {
                delete localStorage['artmap-routehash']
            }
        }

        if (localStorage['artmap-routehash'] !== undefined) {
            window.history.pushState({}, '', '?hash=' + localStorage['artmap-routehash']);

            const response2 = await axios.get(this.BASE_URL + '?action=qr&routehash=' + localStorage['artmap-routehash']);
            this.setState({currentRouteQrImage: response2.data})

            const responseRoute = await axios.get(this.BASE_URL + '?action=route&routehash=' + localStorage['artmap-routehash']);
            let routePoints = JSON.parse(responseRoute.data.content)

            if (routePoints !== null) {
                let routePointsPlusInfo = routePoints.map((e) =>
                    this.state.coos.filter((e2) => e2.coos.lat === e.lat && e2.coos.lng === e.lng)[0])

                this.setState({
                    pathCoordinates: routePoints,
                    routePoints: routePointsPlusInfo
                })
            }
        } else {
            // Create new route
            console.log('new route')
            this.openToast('Select a point on the map')
            this.saveToServer([])
        }
    }

    async loadPoints() {
        const url = this.BASE_URL + "?action=loadpoints"
        const response = await axios.get(url);

        try {
            let points = response.data.points
            this.setState({coos: points, isLoading: false});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    showInfoPanel(position, thisRef) {
        console.log('showInfoPanel')

        this.openToast('Add it to your route!')

        this.hideRoutePanel()
        thisRef.setState({infoPanelOpen: true})

        const foundInArray = thisRef.state.coos.findIndex(e => e.coos.lat === position.lat && e.coos.lng === position.lng)
        if (foundInArray !== -1) {
            let point = thisRef.state.coos[foundInArray]
            point.hasExternalWebsiteIcon = point.website !== null && point.website.indexOf('https') === 0 ? '' : '↗';

            thisRef.setState({hoverPoint: point, mapCenter: {lat: point.coos.lat - 0.005, lng: point.coos.lng}})
        }
    }

    showInfoPanel2(position) {
        console.log('showInfoPanel2')
        //this.hideRoutePanel()
        // this.setState({infoPanelOpen: true})

        const foundInArray = this.state.coos.findIndex(e => e.coos.lat === position.lat && e.coos.lng === position.lng)
        if (foundInArray !== -1) {
            let point = this.state.coos[foundInArray]
            point.hasExternalWebsiteIcon = point.website !== null && point.website.indexOf('https') === 0 ? '' : '↗';
            this.setState({routePanelOpen: false, infoPanelOpen: true, hoverPoint: point,
                mapCenter: {lat: point.coos.lat, lng: point.coos.lng}
                })
        }
    }

    reshowInfoPanel2 = (e) => {
        console.log('reshowInfoPanel2')
        // console.log(e)
        // e.stopPropagation();
        // e.nativeEvent.stopImmediatePropagation();
        this.hideIframePanel()
        //this.setState({infoPanelOpen: true})
    }

    showRoutePanel() {
        this.hideInfoPanel()
        this.setState({routePanelOpen: true, eventsInfoPanelOpen: false, sharePanelOpen: false})
    }

    showSharePanel() {
        console.log('showSharePanel')
        this.setState({sharePanelOpen: true, routePanelOpen: false, eventsInfoPanelOpen: false})
    }

    toggleSharePanel() {
        console.log('toggleSharePanel')
        this.setState({sharePanelOpen: !this.state.sharePanelOpen, routePanelOpen: false, eventsInfoPanelOpen: false})
    }

    clickOnGroundOverlay = (e) => {
        console.log('clickOnGroundOverlay()')
        const closestPoint = this.findClosestPointToClick(e);

        if(this.state.preparedEditMode) {
            console.log('clickOnGroundOverlay => this.state.preparedEditMode')
            if(closestPoint !== null) {
                this.handleStartEditing(closestPoint.coos, this, null)
            }
            else {
                this.addPointOnMap(e, this)
            }
            return
        }

        if(closestPoint !== null) {
            console.log('clickOnGroundOverlay => ' + closestPoint.title)
            this.showInfoPanel2(closestPoint.coos)
        }
        else {
            console.log('clickOnGroundOverlay => hideAllPanels()')
            this.hideAllPanels()
        }
    }

    findClosestPointToClick(e) {
        //console.log(e.latLng.lat())
        let clickLat = e.latLng.lat()
        let clickLng = e.latLng.lng()

        let closestDistance = Number.MAX_VALUE;
        let closestPoint = null;

        this.state.coos.forEach((e) => {
            //let dist = this.dist(e.coos.lng, e.coos.lat, clickLat, clickLng)
            let dist = Math.sqrt(
                Math.pow(69.1 * (e.coos.lat - clickLat), 2) +
                Math.pow(69.1 * (clickLng - e.coos.lng) * Math.cos(e.coos.lat / 57.3), 2))

            // console.log(e.title + ' => dist: ' + dist)
            if (dist < closestDistance) {
                closestDistance = dist
                closestPoint = e
            }
        })

        return closestDistance < (this.state.preparedEditMode ? 0.02 : this.DOT_CLICK_RADIUS) ? closestPoint : null
    }

    // rightClickGroundOverlay = (e) => {
    //     const closestPoint = this.findClosestPointToClick(e)
    //     console.log('right click')
    //     console.log(closestPoint)
    // }

    hideAllPanels() {
        console.log('hideAllPanels')
        // this.hideInfoPanel()
        // this.hideRoutePanel()
        // this.hideSharePanel()
        // this.hideIframePanel()
        this.setState({
            infoPanelOpen: false,
            hoverPoint: null,
            routePanelOpen: false,
            sharePanelOpen: false,
            iframePanelOpen: false,
            eventsInfoPanelOpen: false
        })
    }

    hideInfoPanel() {
        this.setState({infoPanelOpen: false, hoverPoint: null})
    }

    hideRoutePanel() {
        this.setState({routePanelOpen: false})
    }

    hideSharePanel() {
        this.setState({sharePanelOpen: false})
    }

    hideIframePanel() {
        this.setState({iframePanelOpen: false})
    }

    toggleInfoPanel(position, thisRef) {
        if (thisRef.state.infoPanelOpen && thisRef.state.hoverPoint.coos.lat === position.lat
            && thisRef.state.hoverPoint.coos.lng === position.lng) {
            thisRef.hideInfoPanel(position, thisRef)
        } else {
            thisRef.showInfoPanel(position, thisRef)
        }
    }

    toggleRoutePanel() {
        if (this.state.routePanelOpen) {
            this.hideRoutePanel()
        } else {
            this.showRoutePanel()
        }
    }

    // removeFromRouteHandleClick() {
    //     console.log('removeFromRouteHandleClick')
    // }

    // randomAddToRoute() {
    //     return;
    //     console.log('randomAddToRoute')
    //
    //     let randomIndex = Math.floor(Math.random() * this.state.coos.length)
    //     let selectedRandomPoint = this.state.coos[randomIndex]
    //
    //     console.log(selectedRandomPoint)
    //
    //     let event = {
    //         lat: selectedRandomPoint.coos.lat,
    //         lng: selectedRandomPoint.coos.lng
    //     }
    //
    //     this.state.hoverPoint = selectedRandomPoint
    //     this.setState({hoverPoint: selectedRandomPoint})
    //
    //     this.addToRoute(event)
    //
    //     window.setTimeout(() => this.randomAddToRoute(), 3000)
    // }

    addToRoute = (e) => {
        console.log('addToRoute')
        let selectedCoos = this.state.selectedCoos

        let newPathCoos
        const foundInArray = this.state.pathCoordinates.findIndex(e => e.lat === this.state.hoverPoint.coos.lat && e.lng === this.state.hoverPoint.coos.lng)
        if (foundInArray === -1) {
            newPathCoos = [...this.state.pathCoordinates, this.state.hoverPoint.coos]
            selectedCoos.lat = this.state.hoverPoint.coos.lat
            selectedCoos.lng = this.state.hoverPoint.coos.lng
            this.openToast('Added to route')
        } else {
            newPathCoos = [...this.state.pathCoordinates]
            newPathCoos.splice(foundInArray, 1)
            selectedCoos.lat = null
            selectedCoos.lng = null
            this.openToast('Removed from route')
        }

        let routePointsPlusInfo = newPathCoos.map((e) =>
            this.state.coos.filter((e2) => e2.coos.lat === e.lat && e2.coos.lng === e.lng)[0])

        this.setState({pathCoordinates: newPathCoos, selectedCoos: selectedCoos, routePoints: routePointsPlusInfo})
        localStorage['artmap-pathCoordinates'] = JSON.stringify(newPathCoos)

        this.saveToServer(newPathCoos)

        // Skip immediately to route if we are beginners in the use of this app
        if(this.state.routePoints.length === 0) {
            this.hideInfoPanel()
            this.showRoutePanel()
        }

        this.displayWalkedDistance()
    }

    handleStartEditing(position, thisRef, childQuitEditingMode) {
        console.log('enter editing for new point')
        thisRef.setState({editingEnabled: true})

        // Unselect previously edited point
        if (this.state.childQuitEditingMode !== null) {
            this.state.childQuitEditingMode()
        }

        // const foundInArray = this.state.coos.findIndex(
        //     e => {
        //         //let dist = this.dist(e.coos.lng, e.coos.lat, position.lng, position.lat)
        //         let dist = this.dist(e.coos.lng, e.coos.lat, position.lng, position.lat)
        //         console.log(dist)
        //         return dist < this.DOT_CLICK_RADIUS;
        //     })
        //         // e.coos.lat === position.lat && e.coos.lng === position.lng)
        const foundInArray = this.state.coos.findIndex(e => e.coos.lat === position.lat && e.coos.lng === position.lng)

        console.log('foundInArray = ')
        console.log(foundInArray)

        if (foundInArray !== -1) {
            // Pre-fill form
            let coo = this.state.coos[foundInArray]
            console.log(coo)

            this.setState({
                edit_placename: coo.title,
                edit_image: coo.image,
                edit_website: coo.website,
                edit_color: coo.color,
                edit_address: coo.address,
                edit_schedule: coo.schedule,
                edit_events: coo.events,
                edit_delete: false,
            })
        }

        this.setState({
            childQuitEditingMode: childQuitEditingMode,
            editingPoint: {
                lat: position.lat,
                lng: position.lng
            }
        })
    }

    addPointOnMap = (evt) => {
        // if(this.state.editingEnabled) {
        console.log('addPointOnMap')
        // ic('test')
        let selectedCoos = this.state.selectedCoos
        selectedCoos.lat = evt.latLng.lat()
        selectedCoos.lng = evt.latLng.lng()

        let newPoint = {}
        newPoint.title = ''

        newPoint.coos = {
            lat: selectedCoos.lat,
            lng: selectedCoos.lng,
        }
        newPoint.color = '#00ff00'
        newPoint.isNew = true;

        let moreCoos = [...this.state.coos, newPoint]
        this.setState({
            coos: moreCoos,
            selectedCoos: selectedCoos,
            //mapCenter: {lat: newPoint.coos.lat - 0.005, lng: newPoint.coos.lng}
        })
        // }
    }

    onInputchange(event) {
        console.log('onInputchange')
        console.log(event.target.name)
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onInputchangeFestival(event) {
        this.onInputchange(event)
        this.setState({
            selectedFestival: this.festivalsInfo.filter((e) => e.id === event.target.value)[0],
            eventsInfoPanelOpen: true
        })
    }

    onCheckboxChange(event) {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    onCheckboxEventChange(event) {
        this.setState({
            [event.target.name]: event.target.checked
        });

        this.loadPoints();
    }

    async onSubmitForm() {
        if (this.state.editingPoint === null) {
            this.openToast('Please select a point first', true)
            return;
        }

        const url = this.BASE_URL + "?action=savepoint"
        console.log(url)
        const response = await axios.post(url, {
            edit_placename: this.state.edit_placename,
            edit_image: this.state.edit_image,
            edit_website: this.state.edit_website,
            edit_color: this.state.edit_color,
            edit_address: this.state.edit_address,
            edit_schedule: this.state.edit_schedule,
            edit_events: this.state.edit_events,
            edit_delete: this.state.edit_delete,
            edit_lat: this.state.editingPoint.lat,
            edit_lng: this.state.editingPoint.lng,
        });

        try {
            let message = ''
            let toastIsError = false

            message = response.data.message
            if (response.data.status !== 'ok') {
                toastIsError = true
            }

            this.openToast(message, toastIsError)

            if (!toastIsError) {
                this.loadPoints()
            }

            this.state.editingPoint = null
            this.state.childQuitEditingMode()
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    openToast(message, toastIsError = false) {
        if (this.toastTimeout !== null) {
            window.clearTimeout(this.toastTimeout);
            this.toastTimeout = null;
        }

        this.setState({showToast: true, toastMessage: message, toastIsError: toastIsError});
        this.toastTimeout = window.setTimeout(() => {
            this.setState({showToast: false});
            this.toastTimeout = null
        }, 2000)
    }

    deleteMyRoute() {
        let selectedCoos = this.state.selectedCoos
        selectedCoos.lat = null
        selectedCoos.lng = null

        localStorage['artmap-pathCoordinates'] = JSON.stringify([])
        delete localStorage['artmap-routehash']

        window.history.pushState({}, '', '/');
        window.location.reload()
    }

    shareDialog() {
        // https://monsterspost.com/how-to-add-social-media-icons-website/
        // https://www.vecteezy.com/vector-art/95039-free-social-media-icons
        // https://www.legendblogs.com/index.php/how-to-create-social-sharing-links-for-facebook,-whatsapp-and-more


        if (navigator.share) {
            navigator.share({
                title: 'WebShare API Demo',
                url: 'https://bcnartmap.local:3000?hash=' + this.state.currentRouteHash
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            // fallback
        }
    }

    shortestPath() {
        console.log('shortestPath')

        let tsp = new Tsp()
        let shortestRoute = tsp.getShortestRoute(this.state.pathCoordinates)
        console.log(shortestRoute)

        let newCoos = []

        shortestRoute.map((e, index) => {
            newCoos.push(this.state.pathCoordinates[e])
            return null;
        })

        this.displayWalkedDistance()

        // this.setState({pathCoordinates: newCoos})

        this.saveToServer(newCoos)
    }

    displayWalkedDistance() {
        let totalLength = 0

        this.state.pathCoordinates.map((e, index) => {
            if (index > 0) {
                let distance = this.dist(
                    this.state.pathCoordinates[index - 1].lat,
                    this.state.pathCoordinates[index - 1].lng,
                    this.state.pathCoordinates[index].lat,
                    this.state.pathCoordinates[index].lng
                )

                totalLength += distance
                console.log('distance ' + (index) + ' to ' + (index + 1) + ' = ' + (distance * 10000))
            }
            return null;
        })

        totalLength *= 100000

        let totalLengthText = ''

        if (totalLength >= 1000) {
            totalLength = Math.round((totalLength / 1000) * 10) / 10
            totalLengthText = totalLength.toFixed(1) + 'km'
        } else {
            totalLength = Math.round(totalLength)
            totalLengthText = totalLength.toFixed() + 'm'
        }

        console.log('total length = ' + totalLength)
        this.setState({walkedDistanceText: totalLengthText})
        this.openToast(<div>{totalLengthText} <Trans>a vuelo de pájaro</Trans></div>)
    }

    // dist = (x1, y1, x2, y2) => Math.sqrt(Math.pow(69.1 * (y1 - y2), 2) + Math.pow(69.1 * (x2 - x1) * Math.cos(y1 / 57.3), 2))
    // let dist = Math.sqrt(
    //     Math.pow(69.1 * (e.coos.lat - clickLat), 2) +
    //     Math.pow(69.1 * (clickLng - e.coos.lng) * Math.cos(e.coos.lat / 57.3), 2))
    dist = (x1, y1, x2, y2) => Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2))

    boundsChanged() {
        // console.log('boundsChanged')
        // if(!this.state.isMoving) {
        //     this.setState({isMoving: true})
        // }
    }

    async onIdle() {
        // await this.loadPoints()

        // console.log('onIdle')
        // if(this.state.isMoving) {
        //     this.setState({isMoving: false})
        // }
    }

    openWebsite(url = null) {
        // console.log(this.state.hoverPoint.website)
        let urlToOpen = null

        if(url !== null) {
            urlToOpen = url
        }
        else {
            if (this.state.hoverPoint.website !== null) {
                urlToOpen = this.state.hoverPoint.website
            }
        }

        if (urlToOpen !== null && urlToOpen.indexOf('https') !== 0) {
            this.setState({
                iframePanelOpen: true,
                iframePanelInformation2Open: true,
            })

            window.setTimeout(() => {
                this.setState({iframePanelOpen: false, iframePanelInformation2Open: false})

                // Insecure URLs as redirect blank
                window.open(urlToOpen, '_blank');
            }, 2000)
        } else {
            // Secure URLs can open in iframe
            this.setState({
                iframePanelOpen: true,
                iframePanelInformationOpen: true,
                iframeUrl:
                // 'https://www.google.com/search?q=' + urlToOpen + '&btnI=Im+Feeling+Lucky'
                urlToOpen
            })

            window.setTimeout(() => {
                this.setState({iframePanelInformationOpen: false})
            }, 500)
        }
    }

    showWelcomePanel() {
        this.setState({welcomePanelOpen: true})
    }

    toggleEventsInfoPanel() {
        this.setState({eventsInfoPanelOpen: !this.state.eventsInfoPanelOpen, routePanelOpen: false, sharePanelOpen: false })
    }

    // showEventsInfoPanel() {
    //     this.setState({eventsInfoPanelOpen: true})
    // }

    closeWelcomePanel() {
        this.setState({welcomePanelOpen: false})

        // Load events panel with default event
        window.setTimeout(() => {
            this.setState({routePanelOpen: true})
        }, 0)
    }

    closeEventsInfoPanel() {
        this.setState({eventsInfoPanelOpen: false})
    }

    handleKeypress = (e) => {
        if(this.state.editingPoint === null && Globals.debugMode) {
            console.log('handleKeypress')
            if (e.key === 'Enter') {
                console.log('preparedEditMode: ' + (!this.state.preparedEditMode))
                this.setState({preparedEditMode: !this.state.preparedEditMode})
            }
        }
    }

    render() {
        var thisRef = this

        let pointInfo = []

        if (this.state.hoverPoint !== null) {
            let addOrRemoveAction;

            if (this.state.pathCoordinates.filter((e) => e.lat === this.state.hoverPoint.coos.lat && e.lng === this.state.hoverPoint.coos.lng).length === 0) {
                addOrRemoveAction = <button className="button button-web-official" onClick={this.addToRoute.bind(this)}><Trans>Agregar a mi ruta</Trans></button>
            } else {
                addOrRemoveAction =
                    <button className="button button-web-official" onClick={this.addToRoute.bind(this)}><Trans>Quitar de mi ruta</Trans></button>
            }

            let hasArrowNext = null
            let hasArrowPrev = null
            let elementIsInRoute = this.state.pathCoordinates.filter((e) => e.lat === this.state.hoverPoint.coos.lat && e.lng === this.state.hoverPoint.coos.lng).length > 0
            let arrowIndex = null
            if (elementIsInRoute) {
                for(let i = 0; i < this.state.pathCoordinates.length; i++) {
                    let pathItem = this.state.pathCoordinates[i]
                    if(pathItem.lat === this.state.hoverPoint.coos.lat && pathItem.lng === this.state.hoverPoint.coos.lng) {
                        arrowIndex = i
                    }
                }

                if(arrowIndex !== null && arrowIndex < this.state.pathCoordinates.length - 1) {
                    hasArrowNext = this.state.pathCoordinates[arrowIndex + 1]
                }

                if(arrowIndex !== null && arrowIndex > 0) {
                    hasArrowPrev = this.state.pathCoordinates[arrowIndex - 1]
                }
            }

            pointInfo = <div>
                {hasArrowNext ? <div className={'arrow-next'} title="Parada siguiente en la ruta" onClick={() => {this.showInfoPanel2(hasArrowNext)}}>
                    <div></div>
                    <div></div>
                </div> : null}

                {hasArrowPrev ? <div className={'arrow-prev'} title="Para anterior en la ruta" onClick={() => {this.showInfoPanel2(hasArrowPrev)}}>
                    <div></div>
                    <div></div>
                </div> : null}

                <div className="title" onClick={this.hideInfoPanel.bind(this)}>
                    {arrowIndex !== null ? <div className={'arrow-number'}>{(arrowIndex + 1)}</div> : null}
                    <strong>{this.state.hoverPoint.title}</strong>
                    <br />
                    {this.state.hoverPoint.address}
                    <br />
                </div>

                {this.state.hoverPoint.image !== '' ? <div><img src={this.state.hoverPoint.image} className="mb-1 rounded" alt="Gallery"></img><br /></div> : null}

                <div onClick={this.openWebsite.bind(this, null)} className="underline cursor-pointer">
                    <Trans>Abrir página oficial de la galería</Trans> {this.state.hoverPoint.hasExternalWebsiteIcon}
                </div>

                <div className="schedules-two-cols">
                    {this.state.hoverPoint.schedule !== '' ? <div className="schedule">
                        <div className={'blue-dot-festival-small blue-dot-festival black-dot'}></div>
                        <strong><Trans>Horarios normales</Trans></strong><br />
                        {this.state.hoverPoint.schedule}
                    </div> : null}
                    {this.state.hoverPoint.events === 'EVTID003' ? <div className="schedule">
                        <div className={'blue-dot-festival-small blue-dot-festival'}></div>
                        <strong><Trans>Horarios festival</Trans></strong><br />
                        Jue 15.09: 18h, <Trans>apertura público general</Trans>.<br />
                        Vie 16 y Sáb 17.09: 11-20h.<br />
                        Dom 18.09: 11 - 15h
                    </div> : null}
                </div>

                <br />
                {addOrRemoveAction}
                <button className="button button-web-official" onClick={this.showRoutePanel.bind(this)}><Trans>Ver toda la ruta</Trans></button>
            </div>
        }

        return <div onKeyPress={this.handleKeypress.bind(this)}>
            <Map pathCoordinates={this.state.pathCoordinates}
                 thisRef={thisRef}
                 //handleStartEditing={this.handleStartEditing}
                 // toggleInfoPanel={this.toggleInfoPanel}
                 toggleInfoPanel={this.clickOnGroundOverlay.bind(this)}
                 //showInfoPanel={this.showInfoPanel}
                 //hideInfoPanel={this.hideInfoPanel}
                 coos={this.state.isMoving ? [] : this.state.coos}
                 //onClick={this.hideAllPanels.bind(this)}
                 onClickGroundOverlay={this.clickOnGroundOverlay.bind(this)}
                 //onRightClickGroundOverlay={this.rightClickGroundOverlay.bind(this)}
                 onBoundsChanged={this.boundsChanged.bind(this)}
                 onIdle={this.onIdle.bind(this)}
                 //onRightClick={this.addPointOnMap}
                 center={this.state.mapCenter}
                 filterEvent={this.state.filterEvent}
                 hoverPoint={this.state.hoverPoint}
                 bottomPanelsOpen={this.state.routePanelOpen || this.state.infoPanelOpen}
            ></Map>

            <div className={this.state.preparedEditMode ? 'prepared-edit-mode' : ''}>
            </div>

            <div className={'left-panel ' + (this.state.editingPoint !== null ? 'open' : '')}>
                <h1>Edit</h1>
                <fieldset>
                    <input type="text" name="edit_placename" placeholder="Place name" value={this.state.edit_placename}
                           onChange={this.onInputchange}></input>
                    <input type="text" name="edit_image" placeholder="Image" value={this.state.edit_image}
                           onChange={this.onInputchange}></input>
                    <input type="text" name="edit_website" placeholder="Website" value={this.state.edit_website}
                           onChange={this.onInputchange}></input>
                    <select name="edit_color" value={this.state.edit_color} onChange={this.onInputchange}>
                        <option value="-1">-- Please select --</option>
                        <option value="#EVTID001">Yellow - Tallers Oberts Poblenou 09/2021</option>
                        <option value="#EVTID002">Blue - Swab 10/2021</option>
                    </select>
                    <textarea placeholder="Address" name="edit_address" value={this.state.edit_address}
                              onChange={this.onInputchange}
                              rows="3">
                    </textarea>
                    <textarea placeholder="Schedule" name="edit_schedule" value={this.state.edit_schedule}
                              onChange={this.onInputchange}
                              rows="6">
                    </textarea>
                    <select multiple={true} name="edit_events" onChange={this.onInputchange} value={this.state.edit_events}>
                        <option value="-1">-- Please select --</option>
                        <option value="EVTID001">Tallers Oberts Poblenou 09/2021</option>
                        <option value="EVTID002">Swab 10/2021</option>
                        <option value="EVTID003">BGW 09/2022</option>
                    </select>

                    <div className="float-left">
                        <label htmlFor="delete"><input type="checkbox" name="edit_delete" value="1" id="delete"
                                                       checked={this.state.edit_delete}
                                                       onChange={this.onCheckboxChange}></input> Delete</label>
                    </div>

                    <input type="submit" value="Save" onClick={this.onSubmitForm}></input>
                </fieldset>
            </div>
            <div className={'share-panel ' + (this.state.sharePanelOpen ? 'open ' : '') + (Globals.debugMode ? '' : 'shake-animation')}
                 onClick={this.toggleSharePanel.bind(this)}>
                {/*<a href={'whatsapp://send?text=https://bcnartmap.local:3000?hash=' + this.state.currentRouteHash} data-action="share/whatsapp/share" onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;" target="_blank" title="Share on whatsapp">Whatsapp</a>*/}
                <img src={this.state.currentRouteQrImage} className="qr" alt=""></img>

                <div className="social">
                    <strong><Trans>Comparte tu ruta!</Trans></strong>
                    <div className="dynamic-sm">
                        <Trans>Haz que alguien escanee el</Trans> <br/>
                        <Trans>código QR, o simplemente</Trans>
                        <button href="#" onClick={this.shareDialog.bind(this)}
                           className="block bg-green-500 rounded p-2 my-2 text-white font-bold w-full">
                            <img src="share-icon-white.png" alt=""></img>
                            <Trans>haz clic aquí</Trans></button>
                        <Trans>para compartir via Whatsapp, email, etc.</Trans>
                    </div>
                </div>
            </div>
            <div className={'festival-panel'}
                onClick={this.toggleEventsInfoPanel.bind(this)}>
                <div class={'blue-dot-festival'}></div>
                <div><strong><Trans>Selección actual:</Trans></strong> Barcelona Gallery Weekend</div>
            </div>
            <div className={'route-panel ' + (this.state.routePanelOpen ? 'open ' : '')}>
                <div className="close-button-wrapper">
                    <div className="close-button" onClick={this.hideRoutePanel.bind(this)}>×</div>
                </div>
                <div className="title" onClick={this.toggleRoutePanel.bind(this)}><Trans>Mi ruta</Trans></div>
                <Route pathCoordinates={this.state.routePoints}
                       items={this.state.pathCoordinates.map((e) => e.lat + ';' + e.lng)}
                       showInfoPanel={this.showInfoPanel2.bind(this)}
                    // removeFromRouteHandleClick={this.removeFromRouteHandleClick.bind(this)}
                       updateRouteOrder={this.updateRouteOrder.bind(this)}></Route>
                <button onClick={this.deleteMyRoute.bind(this)} className="button button-web-official"><Trans>Reiniciar</Trans></button>
                &nbsp;
                <button onClick={this.shortestPath.bind(this)} className="button button-web-official"><Trans>Optimización auto.</Trans></button>
            </div>
            <div className={'point-info-panel ' + (this.state.infoPanelOpen ? 'open ' : '')}>
                <div className="close-button-wrapper">
                    <div className="close-button" onClick={this.hideInfoPanel.bind(this)}>×</div>
                </div>
                {pointInfo}
            </div>


            {/*<div className={'events-selector-panel'}>*/}
            {/*    <strong>Filter places by festival</strong><br />*/}
            {/*    <label className="cursor-pointer" htmlFor="event1">*/}
            {/*        <input type="radio" id="event1" name="filterEvent"*/}
            {/*                                   value="EVTID001"*/}
            {/*                                   checked={this.state.filterEvent === "EVTID001"}*/}
            {/*                                   onChange={this.onInputchangeFestival}*/}
            {/*                                   onClick={this.showEventsInfoPanel.bind(this)}*/}
            {/*                    >*/}
            {/*    </input>Tallers Oberts Poblenou 2021</label>*/}
            {/*    <label className="cursor-pointer" htmlFor="event2">*/}
            {/*        <input type="radio" id="event2" name="filterEvent"*/}
            {/*                                   value="EVTID002"*/}
            {/*                                   checked={this.state.filterEvent === "EVTID002"}*/}
            {/*                                   onChange={this.onInputchangeFestival}*/}
            {/*                                   onClick={this.showEventsInfoPanel.bind(this)}*/}
            {/*    >*/}
            {/*        </input>Swab Barcelona Art Fair 2021</label>*/}

            {/*    <label className="cursor-pointer" htmlFor="event2">*/}
            {/*        <input type="radio" id="event2" name="filterEvent"*/}
            {/*               value="EVTID002"*/}
            {/*               checked={this.state.filterEvent === "EVTID002"}*/}
            {/*               onChange={this.onInputchangeFestival}*/}
            {/*               onClick={this.showEventsInfoPanel.bind(this)}*/}
            {/*        >*/}
            {/*        </input>Barcelona Gallery Weekend 2022</label>*/}

            {/*    /!*<label className="cursor-pointer" htmlFor="event3">*!/*/}
            {/*    /!*    <input type="radio" id="event3" name="filterEvent"*!/*/}
            {/*    /!*           value=""*!/*/}
            {/*    /!*           checked={this.state.filterEvent === ""}*!/*/}
            {/*    /!*           onChange={this.onInputchangeFestival}*!/*/}
            {/*    /!*           onClick={this.showEventsInfoPanel.bind(this)}*!/*/}
            {/*    /!*    >*!/*/}
            {/*    /!*    </input>View all galleries</label>*!/*/}

            {/*</div>*/}
            <div className={'point-info-panel ' + (this.state.eventsInfoPanelOpen ? 'open ' : '')}>
                <div className="close-button-wrapper">
                    <div className="close-button" onClick={this.toggleEventsInfoPanel.bind(this)}>×</div>
                </div>
                <div className="title" onClick={this.toggleEventsInfoPanel.bind(this)}><Trans>Selección actual</Trans></div>

                <strong>{this.state.selectedFestival.title}</strong><br />
                {this.state.selectedFestival.description}<br />

                <img src={this.state.selectedFestival.image} className="mb-1 mx-auto max-h-image" alt="Gallery"></img>
                <br />
                <div className="cursor-pointer blue-dot-festival-wrapper">
                    <div className={'blue-dot-festival'}></div>
                    <div><Trans>Lugares en azul en el mapa.</Trans></div>
                </div>
                <br />
                <div onClick={() => this.openWebsite(this.state.selectedFestival.website)} className="button button-web-official">
                    <Trans>Más información en la web oficial</Trans>
                </div>
                {/*<button className="button" onClick={this.addToRoute.bind(this)}>Add to my route</button>*/}
            </div>

            {/*<div className="walked-distance">*/}
            {/*    {this.state.walkedDistanceText}*/}
            {/*</div>*/}

            {/*<div className="toolbar-panel">*/}
            {/*    <button className="bounds-button">*/}
            {/*        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"*/}
            {/*             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"*/}
            {/*             width="612px" height="612px" viewBox="0 0 612 612"*/}
            {/*             style={{enableBackground: 'new 0 0 612 612'}} xmlSpace="preserve"*/}
            {/*             className="bounds-icon">*/}
            {/*            <g id="full_screen">*/}
            {/*                <g>*/}
            {/*                    <path d="M561,0H51C22.882,0,0,22.882,0,51v510c0,28.118,22.882,51,51,51h510c28.118,0,51-22.882,51-51V51*/}
            {/*                        C612,22.882,589.118,0,561,0z M578,561c0,9.367-7.633,17-17,17H51c-9.367,0-17-7.633-17-17V51c0-9.367,7.633-17,17-17h510*/}
            {/*                        c9.367,0,17,7.633,17,17V561z M68,186.15L186.15,68H68V186.15z M544,186.15V68H425L544,186.15z M68,544h118.15L68,425V544z*/}
            {/*                         M544,544V425L425,544H544z"/>*/}
            {/*                </g>*/}
            {/*            </g>*/}
            {/*        </svg>*/}

            {/*    </button>*/}
            {/*</div>*/}
            <div
                className={'toast-wrapper' + (this.state.showToast ? ' on' : ' off') + (this.state.toastIsError ? ' error' : '')}>
                <div className="toast">
                    {this.state.toastMessage}
                </div>
            </div>
            <div className={'iframe-panel ' + (this.state.iframePanelOpen ? 'open ' : '')}>
                <div className="close-button-wrapper">
                    <div className="close-button close-button--variant2" onClick={this.reshowInfoPanel2}>×</div>
                </div>
                <div className="inner">
                    <iframe src={this.state.iframeUrl} title="External gallery website">
                    </iframe>
                    <div className={'information ' + (this.state.iframePanelInformationOpen ? 'open' : '')}>
                        <Trans>Cargando</Trans><br/>
                        ...
                    </div>
                    <div className={'information ' + (this.state.iframePanelInformation2Open ? 'open' : '')}>
                        Vas a ser redirigido hacia una web externa.<br/>
                        Usa el botón 'página anterior' de tu navegador
                        para volver a artmapi.com después!
                    </div>
                </div>
            </div>
            <div className={'iframe-panel ' + (this.state.welcomePanelOpen ? 'open ' : '')}>
                <div className="inner">
                    <div className="welcome-screen">
                        <Intro></Intro>
                        <div style={{fontSize: '22px'}}>
                            <br/>
                            <strong>Descubre galerías de arte en Barcelona</strong><br/>
                            Haz tu propia ruta y compartala en tiempo real con tus compañeros/as de excursión.
                            <br /><br />
                            <button className="start" onClick={this.closeWelcomePanel.bind(this)}>
                                Explorar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent
                enableDeclineButton
                onDecline={() => {
                    alert("This application cannot function without cookies. Please leave the website by navigating to another web page.");
                }}
                style={{ background: "#fff", color: '#000' }}
                buttonStyle={{ border: '2px solid #00c7ff', background: "#00c7ff", color: "white", fontSize: "13px" }}
                declineButtonStyle={{ border: '2px solid #00c7ff', background: "#white", color: "#00c7ff", fontSize: "13px", margin: '0' }}
            >
                Utilizamos cookies con el fin de permitir el correcto funcionamiento de la web
                y medir el uso de esta página. Para más información puedes visitar nuestra <Link onClick={() => {window.location.pathname="/es" + `/cookies`}} className={'underline'}><Trans>política de cookies</Trans></Link>.
            </CookieConsent>
        </div>
    }
}

export default Editor;
