import { useState, React } from 'react';
import { Trans } from 'react-i18next';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    //PointerSensor,
    useSensor,
    useSensors, TouchSensor, MouseSensor,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {RouteItem} from './RouteItem';

export function Route(props) {
    //const [items, setItems] = useState([...props.pathCoordinates]);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeId, setActiveId] = useState(null);

    return (
        <div>
            <div className="text-sm">
                <Trans>Aquí está la vista general de tu ruta.</Trans><br />
                <Trans>Agrega y quita paradas según lo que te apetece ir a ver.</Trans>
            </div>

            <div className="scrollable-wrapper">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <SortableContext
                        items={props.items}
                        strategy={verticalListSortingStrategy}
                    >
                        {props.items.map((id,index) => {
                            return props.pathCoordinates[index] !== null ?
                                <RouteItem key={id} id={id} index={index + 1} lastIndex={props.items.length} object={props.pathCoordinates[index]}
                                           handle={true}
                                           showInfoPanel={props.showInfoPanel} />
                                    : <></>})}
                        {/*<DragOverlay>*/}
                        {/*    {true || activeId ? (*/}
                        {/*        <div*/}
                        {/*            id="drag-overlay"*/}
                        {/*            style={{*/}
                        {/*                width: "100px",*/}
                        {/*                height: "100px",*/}
                        {/*                backgroundColor: "red"*/}
                        {/*            }}*/}
                        {/*        ></div>*/}
                        {/*    ) : null}*/}
                        {/*</DragOverlay>*/}
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    );

    function handleDragStart(event) {
        console.log('handleDragStart')
        setActiveId(event.active.id);
    };

    function handleDragEnd(event) {
        console.log('handleDragEnd')

        setActiveId(null);

        const {active, over} = event;

        console.log('event:')
        console.log(event.active)
        console.log(event.over)

        if (active.id === over.id) {
            // console.log('click')
            // console.log(props)
            // let foundCoosIndex = props.pathCoordinates.findIndex((e) => e.coos.lat + ';' + e.coos.lng === active.id)
            //
            // if(foundCoosIndex !== -1) {
            //     props.showInfoPanel(props.pathCoordinates[foundCoosIndex].coos)
            // }
            return
        }
        else {
            let items = props.items

            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            let newOrderedArray = arrayMove(items, oldIndex, newIndex);
            props.updateRouteOrder(newOrderedArray)
        }
    }
}
