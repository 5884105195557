import {Component} from 'react'
import React from 'react'
import InfoWindow, {Marker} from "@react-google-maps/api"

class CustomMarker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: false,
            editing: false
        }

        this.quitEditingMode = this.quitEditingMode.bind(this)
    }

    componentDidMount() {
        // if (this.props.pathCoordinates.filter((e) => e.lat === this.props.position.lat
        //     && e.lng === this.props.position.lng).length > 0) {
        //     this.setState({selected: true})
        // }
    }

    onMarkerClick = (evt) => {
        this.setState({selected: !this.state.selected})
        // this.props.addToRoute(this.props.position, this.props.thisRef);
    }

    setSelected = () => {
        this.setState({selected: true})
    }

    setUnselected = () => {
        this.setState({selected: true})
    }

    onRightClick = (evt) => {
        // Enter editing mode
        this.props.handleStartEditing(this.props.position, this.props.thisRef, this.quitEditingMode);
        this.setState({editing: true})
    }

    showInfoPanel = (evt) => {
        this.props.showInfoPanel(this.props.position, this.props.thisRef);
    }

    hideInfoPanel = (evt) => {
        this.props.hideInfoPanel(this.props.position, this.props.thisRef);
    }

    quitEditingMode() {
        this.setState({editing: false})
    }

    render() {
        return (
            <div>
                <Marker
                    //onClick={() => this.props.toggleInfoPanel(this.props.position, this.props.thisRef)}
                    onClick={this.props.toggleInfoPanel}
                    //onRightClick={this.onRightClick}
                    // onMouseOver={this.showInfoPanel}
                    // onMouseOut={this.hideInfoPanel}
                    position={this.props.position2}
                    // visible={false}
                    icon={this.state.editing ? this.props.iconEditing : ((this.props.hoverPoint !== null ? ((this.props.coos.findIndex((e) => this.props.hoverPoint.coos.lat === this.props.position.lat && this.props.hoverPoint.coos.lng === this.props.position.lng) !== -1)) : false) ? this.props.iconSelected : this.props.iconUnselected)}
                    title={this.props.title2}
                    name={this.props.name2}
                    label={this.props.label2}
                    key={this.props.key2}
                    // animation={window.google.maps.Animation.DROP}
                    {...this.props}
                />
            </div>
        )
    }
}

export default CustomMarker;
