import './App.css';

function Intro() {
    return (
        <div className="hero_title__2dqLj">
            <div className="hero_texts__1leYi">
                <div className="animated-gradient-text_background__104Eo animated-gradient-text_background-1__2q1-A"
                    style={{'--content': '"art"', '--start-color': '#ff0000', '--end-color': '#ffc700', 'display': 'inline-block'}}>
                    <span
                        className="animated-gradient-text_foreground__2kjjY animated-gradient-text_foreground-1__3O_nT">
                        art</span>
                </div>
                <div className="animated-gradient-text_background__104Eo animated-gradient-text_background-2__3r8da inline-block"
                     style={{'--content': '"mapi"', '--start-color': '#ffc700', '--end-color': '#ff0000', 'display': 'inline-block'}}>
                    <span
                        className="animated-gradient-text_foreground__2kjjY animated-gradient-text_foreground-2__BYeW7">
                        mapi</span>
                </div>

                <div>
                    <img src="astronaut.png" style={{'width': '150px', 'marginLeft': 'auto', 'marginRight': 'auto', 'marginTop': '30px'}} />
                </div>
            </div>
        </div>
    );
}

export default Intro;
