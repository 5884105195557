import i18n from "i18next";
import {initReactI18next, Trans} from "react-i18next";
import React from "react";
import LngDetector from "i18next-browser-languagedetector";


const detectionOptions = {
    // order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
    order: ['path'],
    lookupFromPathIndex: 0

}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    es: {
        translation: {
        }
    },
    en: {
        translation: {
            "Mapa": "Map",
            "Proyecto": "Project",
            "Ayuda": "Help",
            "Contacto": "Contact",

            "Agregar a mi ruta": "Add to my route",
            "Selección actual:": "Current selection:",
            "Selección actual": "Current selection",

            "Buscar galería o museo...": "Find gallery or museum...",

            // Event panel
            "Lugares en azul en el mapa.": "Blue dots on the map.",
            "Más información en la web oficial": "More information on the official website",
            "Cargando": "Loading",

            // Share panel
            "Comparte tu ruta!": "Share your route!",
            "Haz que alguien escanee el": "Make someone scan the",
            "código QR, o simplemente": "QR code, or simply",
            "haz clic aquí": "click here",
            "para compartir via Whatsapp, email, etc.": "to share via Whatsapp, mail, etc.",

            // Route panel
            "Mi ruta": "My route",
            "Aquí está la vista general de tu ruta.": "Here is the overview of your route.",
            "Agrega y quita paradas según lo que te apetece ir a ver.": "Add and remove stops based on what you feel like going to see.",
            "Reinicar": "Reset",
            "Optimización auto.": "Auto optimize",
            "Quitar de mi ruta": "Remove from my route",
            "Ver toda la ruta": "See the whole route",
            "Abrir página oficial de la galería": "Open official gallery page",
            "Horarios normales": "Regular opening hours",
            "Horarios festival": "Festival opening hours",
            "apertura público general": "general public opening",
            "Horarios": "Opening hours",
            ' a vuelo de pájaro.': ' as the crow flies',

            // About
            "La iniciativa": "The initiative",

            "En Artmapi encontrarás información sobre todas las galerías y los museos de arte en Barcelona. El proyecto Artmapi es una iniciativa que originó en el año 2021 y que va creciendo con el tiempo, con cada vez más lugares referenciados, con el objetivo de hacer redescubrir al público los artistas que obran y exponen en Barcelona, en particular los creadores que trabajan con pintura, dibujo, escultura, arquitectura y nuevas tecnologías.":
                "In Artmapi you will find information about all the art galleries and museums in Barcelona. The Artmapi project is an initiative that originated in 2021 and that is growing over time, with more and more referenced places, with the aim of making the public rediscover the artists who work and exhibit in Barcelona, ​​in particular the creators who work with painting, drawing, sculpture, architecture and new technologies.",

            "Si conoces a algún lugar, pero no se encuentra en el mapa, o si encuentras alguna información incorrecta, no dudes en": "If you know of a place, but it is not on the map, or if you find some incorrect information, do not hesitate",

            "escribirnos": "to write to us",
            "Aviso relacionado con los datos presentados:": "Notice related to the data presented:",

            "A pesar del cuidado que aportamos en la recopilación y la aportación de información nueva encontrada en la presente web, con el fin de cumplir con el fin de inspirar a la gente a ver más arte, Artmapi no puede garantizar que sea ni correcta ni actualizada en cada momento. Toda la información presentada aquí procede de fuentes públicas que parecen ser reconocidas y no procedemos a verificaciones en lo que concierne la fiabilidad de los datos originales.": "Despite the care we take in collecting and providing new information found on this website, in order to fulfill the goal of inspiring people to see more art, Artmapi cannot guarantee that it is neither correct nor updated at every moment. All the information presented here comes from public sources that seem to be recognized and we do not carry out verifications as regards the reliability of the original data.",

            "¿Cómo funciona?": "How does it work?",

            "Con Artmapi podrás planificar tu propia ruta de visita, haciendo un simple clic en los puntos del mapa que te interesan para añadirlos o quitarlos de tu recorrido. El GPS y la función de optimización de trayecto te ayudarán a crear el mejor trayecto. Finalmente, comparte tu ruta con tus amigos gracias al código QR arriba a la derecha, para que todos tengan la misma información actualizada en su dispositivo.": "With Artmapi you can plan your own visit route, by simply clicking on the points on the map that interest you to add or remove them from your route. The GPS and the route optimization function will help you create the best route. Finally, share your route with your friends thanks to the QR code on the top right, so that everyone has the same updated information on their device.",
            "¿Tienes una pregunta o quieres colaborar con nosotros? Puedes escribirnos en cualquier momento en nuestro email:": "Do you have a question or want to collaborate with us? You can write to us at any time in our email:"
        }
    }
};

i18n
    .use(LngDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: "es",
        detection: detectionOptions,

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        debug: true,

        react: {
            wait: true
        }
    });


export default i18n;
