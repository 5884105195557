import './App.css';
import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import AutoSuggest from "react-autosuggest";
import axios from "axios";
import i18n from "i18next";
import './i18n';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import eventBus from "./EventBus";


function Navbar() {
    const BASE_URL = 'https://bcnartmap.local:3001/artmap/server/index.php'

    const { t, i18n } = useTranslation();
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [points, setPoints] = useState([]);
    const [currentLanguageForceStateUpdate, setCurrentLanguageForceStateUpdate] = useState('en');

    const [menuOpen, setMenuOpen] = useState(false);
    const [searchPanelOpen, setSearchPanelOpen] = useState(false);

    useEffect(async () => {
        await loadPoints()
    }, [])

    async function loadPoints() {
        const url = BASE_URL + "?action=loadpoints"
        const response = await axios.get(url);

        try {
            let points = []
            let index = 0

            response.data.points.forEach((e) => {
                points.push({id: index++, object: e})
            })

            setPoints(points)
        } catch (error) {
        }
    }

    function getSuggestions(value) {
        return points.filter(point =>
            point.object.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.trim().toLowerCase())
        );
    }

    return (
        <div className="navbar">
            <Link to="/">
                <img src="/logo32.png" alt="Artmapi"></img>
                <h1><strong>Artmapi</strong> | Barcelona</h1>
            </Link>
            <div className={'hamburger ' + (menuOpen ? 'open' : '')} onClick={() => setMenuOpen(!menuOpen)}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <nav className={'menu ' + (menuOpen ? 'open' : '')}>
                <h3>Menu</h3>
                <Link onClick={() => {setMenuOpen(false); window.location.pathname="/" + i18n.language + `/`}} className={'active'}><Trans>Mapa</Trans></Link>
                <Link onClick={() => {setMenuOpen(false); window.location.pathname="/" + i18n.language + `/about`}}><Trans>Proyecto</Trans></Link>
                <Link onClick={() => {setMenuOpen(false); window.location.pathname="/" + i18n.language + `/about`}}><Trans>Ayuda</Trans></Link>
                <Link onClick={() => {setMenuOpen(false); window.location.pathname="/" + i18n.language + `/about`}}><Trans>Contacto</Trans></Link>
                <Link to="/es" className={'language-link ' + (i18n.language === 'es' ? 'font-bold' : '')} onClick={() => window.location.pathname = '/es'}>ES</Link>
                &nbsp;/&nbsp;
                <Link to="/en" className={'language-link ' + (i18n.language === 'en' ? 'font-bold' : '')} onClick={() => window.location.pathname = '/en'}>EN</Link>
            </nav>

            <img src="/vector-find-icon-small.jpg" className={'search-icon'} onClick={() => setSearchPanelOpen(!searchPanelOpen)}></img>
            <div className={'search-panel ' + (searchPanelOpen ? 'open' : '')}>
                <AutoSuggest
                    suggestions={suggestions}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    onSuggestionsFetchRequested={({ value }) => {
                        console.log(value);
                        setValue(value);
                        setSuggestions(getSuggestions(value));
                    }}
                    onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
                            // console.log("Selected: " + suggestionValue)
                            setValue('')
                            setSearchPanelOpen(false)
                            eventBus.dispatch("suggestionSelected", {message: suggestion.object})
                        }
                    }
                    getSuggestionValue={suggestion => suggestion.object.title}
                    renderSuggestion={suggestion => <span>{suggestion.object.title}</span>}
                    inputProps={{
                        placeholder: t("Buscar galería o museo..."),
                        value: value,
                        onChange: (_, { newValue, method }) => {
                            setValue(newValue);
                        }
                    }}
                    highlightFirstSuggestion={true}
                />
            </div>
        </div>
    );
}

export default Navbar;
