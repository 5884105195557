import './App.css';
import React from "react";
import { Trans } from 'react-i18next';

function About() {
    return (
        <div>
            <div className="cms-content-wrapper">
                <div className="hero_texts__1leYi">
                    <div className="animated-gradient-text_background__104Eo animated-gradient-text_background-1__2q1-A"
                         style={{'--content': '"art"', '--start-color': '#ff0000', '--end-color': '#ffc700', 'display': 'inline-block'}}>
                    <span
                        className="animated-gradient-text_foreground__2kjjY animated-gradient-text_foreground-1__3O_nT">
                        art</span>
                    </div>
                    <div className="animated-gradient-text_background__104Eo animated-gradient-text_background-2__3r8da inline-block"
                         style={{'--content': '"mapi"', '--start-color': '#ffc700', '--end-color': '#ff0000', 'display': 'inline-block'}}>
                    <span
                        className="animated-gradient-text_foreground__2kjjY animated-gradient-text_foreground-2__BYeW7">
                        mapi</span>
                    </div>

                    <div>
                        <img src="/astronaut.png" style={{'width': '150px', 'marginLeft': 'auto', 'marginRight': 'auto', 'marginTop': '30px'}} />
                    </div>
                </div>
                <h2><Trans>La iniciativa</Trans></h2>
                <p><Trans>En Artmapi encontrarás información sobre todas las galerías y los museos de arte en Barcelona. El proyecto Artmapi es una iniciativa que originó en el año 2021 y que va creciendo con el tiempo, con cada vez más lugares referenciados, con el objetivo de hacer redescubrir al público los artistas que obran y exponen en Barcelona, en particular los creadores que trabajan con pintura, dibujo, escultura, arquitectura y nuevas tecnologías.</Trans></p>
                <p><Trans>Si conoces a algún lugar, pero no se encuentra en el mapa, o si encuentras alguna información incorrecta, no dudes en</Trans> <a href="mailto:contact@artmapi.com" className={'underline'}><Trans>escribirnos</Trans></a>.</p>

                <p><span className={'underline'}><Trans>Aviso relacionado con los datos presentados:</Trans></span> <Trans>A pesar del cuidado que aportamos en la recopilación y la aportación de información nueva encontrada en la presente web, con el fin de cumplir con el fin de inspirar a la gente a ver más arte, Artmapi no puede garantizar que sea ni correcta ni actualizada en cada momento. Toda la información presentada aquí procede de fuentes públicas que parecen ser reconocidas y no procedemos a verificaciones en lo que concierne la fiabilidad de los datos originales.</Trans></p>
                <p>&nbsp;</p>
                <h2 id="help"><Trans>¿Cómo funciona?</Trans></h2>
                <p><Trans>Con Artmapi podrás planificar tu propia ruta de visita, haciendo un simple clic en los puntos del mapa que te interesan para añadirlos o quitarlos de tu recorrido. El GPS y la función de optimización de trayecto te ayudarán a crear el mejor trayecto. Finalmente, comparte tu ruta con tus amigos gracias al código QR arriba a la derecha, para que todos tengan la misma información actualizada en su dispositivo.</Trans></p>
                <p>&nbsp;</p>
                <h2 id="contact"><Trans>Contacto</Trans></h2>
                <p><Trans>¿Tienes una pregunta o quieres colaborar con nosotros? Puedes escribirnos en cualquier momento en nuestro email:</Trans> <a href="mailto:contact@artmapi.com" className={'underline'}>contact@artmapi.com</a>.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>

                <strong>© Copyright Artmapi {'2021 – ' + new Date().getFullYear()}</strong>
            </div>
        </div>
    );
}

export default About;
