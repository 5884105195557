import './App.css';
import Editor from "./Editor";
import ReactGA from 'react-ga4';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import About from "./About";
import Cookies from "./Cookies";
import Error404 from "./Error404";
import Navbar from "./Navbar";
import React, { useEffect, useState } from "react";
import Faq from "./Faq";
import eventBus from "./EventBus";

// import { createStore, combineReducers } from "redux";
// import { render } from "react-dom";
// import { connect, Provider } from "react-redux";
// import { Router, Link, Redirect, Route, Switch } from "react-router-dom";
// import { ConnectedRouter, routerReducer } from "react-router-redux";
// import { createBrowserHistory } from "history";
// import PathToRegexp, { compile, parse } from "path-to-regexp";
// import { I18nextProvider } from "react-i18next";
//
import i18n from "./i18n";
//
// import reducer from "./reducer";

var host = window.location.hostname;
if(window.location.hostname === "www.bcnartmap.local") {
    ReactGA.initialize("G-SXK2Q5LZDC");
    ReactGA.send("pageview");
}

//
//
// const lang = i18n.language;
//
// const store = createStore(
//     combineReducers({
//         app: reducer,
//         routing: routerReducer
//     })
// );
//
//
// const history = createBrowserHistory();


function App() {
    const [forceRefresh , setForceRefresh] = useState(0)

    useEffect(() => {
        console.log('App mounted')

        eventBus.on("forceRefresh", (data) => {
            window.location.reload()
        })

        return () => {
            console.log('App will unmount')
            eventBus.remove('forceRefresh', () => {})
        }
    }, [])

    return (
        <div className="App">
            <Router>
                <Navbar></Navbar>
                <Switch>
                    <Route path="/404">
                        <About></About>
                    </Route>
                    <Route path={`/:locale(en|es)/about`}>
                        <About></About>
                    </Route>
                    {/*<Route path="/faq">*/}
                    {/*    <Faq></Faq>*/}
                    {/*</Route>*/}
                    <Route path={`/:locale(en|es)/cookies`}>
                        <Cookies></Cookies>
                    </Route>
                    <Route path="/error404">
                        <Error404></Error404>
                    </Route>
                    <Route path="/">
                        <Editor></Editor>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
