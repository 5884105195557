import React from 'react'
import { GoogleMap, Polyline, GroundOverlay, useJsApiLoader } from '@react-google-maps/api'
import { Marker, InfoWindow } from '@react-google-maps/api'
import CustomMarker from "./CustomMarker"
import LocationMarker from "./LocationMarker"

const containerStyle = {
    width: '100%',
    // height: 'calc(100% - 25px - 44px)'
    // height: 'calc(100% - 25px)'
    height: 'calc(100% - 25px - 113px)'
};

const containerStyleBottomOpen = {
    width: '100%',
    // height: 'calc(42% - 25px)'
    // height: 'calc(42% - 25px - 44px)'
    height: 'calc(42% - 25px - 93px)'
};

const polylineStyle = {
    strokeColor: "#ff2527",
    strokeOpacity: 0.75,
    strokeWeight: 5,
    icons: [
        {
            offset: "0",
            repeat: "20px"
        }
    ]
}

// https://snazzymaps.com/style/15/subtle-grayscale
const grayscaleStyle = [
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                hue: '#ffc700'
            },
            // {
            //     "saturation": 0
            // },
            {
                "lightness": 65
                // "lightness": -10
            },
            {
                // "visibility": "on"
                // "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            // {
            //     hue: '#ffc700'
            // },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                // "lightness": "30"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                // "lightness": "40"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                // "hue": "#ffc700"
            },
            {
                // "lightness": 30
            },
            {
                // "saturation": -100
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "lightness": -25
            },
            {
                "saturation": -100
            }
        ]
    }
];

// function useMedia(query) {
//     const [matches, setMatches] = useState(window.matchMedia(query).matches);
//
//     // cDM, cDU
//     useEffect(() => {
//         let media = window.matchMedia(query);
//         if (media.matches !== matches) {
//             setMatches(media.matches);
//         }
//         let listener = () => setMatches(media.matches);
//         media.addListener(listener);
//         return () => media.removeListener();
//     }, [query]);
//
//     return matches;
// }

const Map = (props) => {
    const [zoomLevel, setZoomLevel] = React.useState(15)
    const [drawScale, setDrawScale] = React.useState('1')
    const [map, setMap] = React.useState(null)
    //const [isMoving, setIsMoving] = React.useState(false)
    //let isMovingVar = false

    // let small = useMedia("(max-width: 400px)");

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDTyuLe5r5OEWpHY8-8Ux9_og5eHMXwhgM"
    })

    const onLoad = React.useCallback(function callback(map) {
        console.log('onLoad')
        // const bounds = new window.google.maps.LatLngBounds(
        //     new window.google.maps.LatLng(41.366574, 2.114874),
        //     new window.google.maps.LatLng(41.433696, 2.210198)
        // );
        // setMap(map)

        // if (isLoaded) {
        //     console.log('fitting bounds')
        //     map.fitBounds(bounds);
        // }

        if(map) {
            // console.log('fitting bounds')
            // const bounds = new window.google.maps.LatLngBounds(
            //     new window.google.maps.LatLng(41.366574, 2.114874),
            //     new window.google.maps.LatLng(41.433696, 2.210198)
            // );
            setMap(map);
            // map.fitBounds(bounds);
        }

    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        console.log('onUnmount')
        setMap(null)
    }, [])

    function onZoomChanged() {
        // console.log('onZoomChanged: ' + map.zoom)
        // console.log(map)
        // console.log(zoomLevel)
        // console.log(newZoom)
        //console.log(map2)

        if(map !== null) {
        //     console.log(map.zoom)
        //     // console.log(map)
        //     // setMap(null)
        //     //console.log(Math.max(0, 1 - ((map.zoom - 13) / 11) * 2))
        //
            setZoomLevel(map.zoom)
            // setDrawScale(map.zoom > 13 ? (map.zoom > 14 ? (map.zoom > 16 ? (map.zoom > 17 ? '0_05' : '0_2') : '0_5') : '1') : '2')
            // setDrawScale(map.zoom > 13 ? (map.zoom > 14 ? (map.zoom > 16 ? '0_2' : '0_5') : '1') : '2')
            setDrawScale(map.zoom > 13 ? (map.zoom > 14 ? '0_5' : '1') : '2')
            // setDrawScale('1')
        }
        // setZoomLevel(newZoom)
    }

    // const onBoundsChanged = React.useCallback(function callback(map) {
    //     console.log('onBoundsChanged: ' + isMovingVar)
    //     if(!isMovingVar) {
    //         console.log('onBoundsChanged: set')
    //         // setIsMoving(true)
    //         isMovingVar = true
    //     }
    // }, [])
    //
    // const onIdle = React.useCallback(function callback(map) {
    //     console.log('onIdle: ' + isMovingVar)
    //     if(isMovingVar) {
    //         console.log('onIdle: set')
    //         // setIsMoving(false)
    //         isMovingVar = false
    //     }
    // }, [])

    // Render --------------------------------------------------- /

    if (!isLoaded) {
        return <></>
    }

   //  function CircleIcon(options) {
   //      let {
   //          scale,
   //          fillOpacity = 1.0,
   //          fillColor = '#000000',
   //          strokeColor = '#000000',
   //          strokeWeight = 1,
   //      } = options;
   //
   //      let diameter = 2 * scale;
   //
   //      let svg = btoa(`
   //    <svg xmlns="http://www.w3.org/2000/svg" width="${diameter}px" height="${diameter}px">
   //       <circle cx="${scale}" cy="${scale}" fill="${fillColor}" fill-opacity="${fillOpacity}" stroke="${strokeColor}" stroke-width="${strokeWeight}px"/>
   //    </svg>
   // `);
   //
   //      let icon = new window.google.maps.MarkerImage(
   //          'data:image/svg+xml;base64,' + svg,
   //          new window.google.maps.Size(diameter, diameter, 'px', 'px'),
   //          new window.google.maps.Point(0, 0),
   //          new window.google.maps.Point(scale, scale)
   //      )
   //
   //      return icon;
   //  }

    // const defaultMarkerStyle = CircleIcon({
    //     scale: 6,
    //     fillOpacity: 0.8,
    //     fillColor: '#5A7793',
    //     strokeColor: '#FFFFFF',
    //     strokeWeight: 2,
    // });

    // console.log('updating')

    let markers = [];

    props.coos.forEach((coo) => {
        let inRoute = props.pathCoordinates.findIndex((e) => e.lat === coo.coos.lat && e.lng === coo.coos.lng)
        let selected = props.hoverPoint !== null ? ((props.coos.findIndex((e) => props.hoverPoint.coos.lat === coo.coos.lat && props.hoverPoint.coos.lng === coo.coos.lng) !== -1)) : false;
        let labelText = inRoute !== -1 ? (inRoute + 1).toString() : ' '

        // In route or new point
        if(inRoute === -1 && !coo.isNew && !selected) {
            return;
        }

        markers.push(<CustomMarker position={coo.coos}
                                   iconUnselected={{
                                       url: "/marker4_off.png",
                                       anchor: (new window.google.maps.Point(15,59)),
                                       scaledSize: new window.google.maps.Size(30,59),
                                       // path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                       scale: 8,
                                       fillColor: true ? '#ff7979' : coo.color,
                                       // fillColor: inRoute ? '#ccc' : coo.color,
                                       fillOpacity: 0.9,
                                       strokeColor: selected ? "#000000" : "#ff5253",
                                       strokeOpacity: 1,
                                       strokeWeight: 1,
                                   }}
                                   iconSelected={{
                                       url: "/marker4.png",
                                       anchor: (new window.google.maps.Point(15,59)),
                                       scaledSize: new window.google.maps.Size(30,59),
                                       // path: window.google.maps.SymbolPath.CIRCLE,
                                       scale: 13,
                                       fillColor: inRoute ? '#ff9191' : coo.color,
                                       // fillColor: inRoute ? '#ccc' : coo.color,
                                       fillOpacity: 0.9,
                                       strokeColor: "#000000",
                                       strokeOpacity: 1,
                                       strokeWeight: 3,
                                       opacity: 0.2
                                   }}
                                   iconEditing={{
                                       url: "/marker4.png",
                                       anchor: (new window.google.maps.Point(15,59)),
                                       scaledSize: new window.google.maps.Size(30,59),
                                       scale: 13,
                                       fillColor: inRoute ? '#ff7979' : coo.color,
                                       fillOpacity: 0.9,
                                       strokeColor: "#000000",
                                       strokeOpacity: 1,
                                       strokeWeight: 1
                                   }}

                                   title2={'Opens next at ' + coo.schedule}
                                   name2="Name"
                                   label2={{
                                       text: labelText,
                                       fontWeight: "bold",
                                       fontSize: '14px'
                                       // color: 'white'
                                   }}
                                   key2="1"
                                   pathCoordinates={props.pathCoordinates}
                                   coos={props.coos}
                                   handleStartEditing={props.handleStartEditing}
                                   //showInfoPanel={props.showInfoPanel}
                                   hideInfoPanel={props.hideInfoPanel}
                                   toggleInfoPanel={props.toggleInfoPanel}
                                   thisRef={props.thisRef}
                                   hoverPoint={props.hoverPoint}
        >
            {/*<InfoWindow pixelOffset={"20"} position={coo.coos}>*/}
            {/*    <div className="map-label">*/}
            {/*        <strong className="font-bold text-gray-400">{coo.schedule}</strong>*/}
            {/*    </div>*/}
            {/*</InfoWindow>*/}
        </CustomMarker>)
    })

    return <div className="fixed top-97px map-container">
        <GoogleMap
            mapContainerStyle={props.bottomPanelsOpen ? containerStyleBottomOpen : containerStyle}
            center={props.center}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={props.onBoundsChanged}
            onIdle={props.onIdle}
            onZoomChanged={onZoomChanged}

            options={{
                styles: grayscaleStyle,
                streetViewControl: false,
                fullscreenControl:false,
                mapTypeControl: false,
                restriction: {
                    latLngBounds: new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(41.366574 - 0.09, 2.114874 - 0.09),
                        new window.google.maps.LatLng(41.433696 + 0.09, 2.210198 + 0.09)
                    ),
                    strictBounds: true,
                }
            }}

            onClick={props.onClick}
            //onRightClick={props.onRightClickGroundOverlay}
        >

            <LocationMarker key="location" thisRef={props.thisRef}></LocationMarker>

            {/*<GroundOverlay*/}
            {/*    url={'https://bcnartmap.local:3001/artmap/groundoverlay--1.png'}*/}
            {/*    bounds={new window.google.maps.LatLngBounds(*/}
            {/*        new window.google.maps.LatLng(41.366574, 2.114874),*/}
            {/*        new window.google.maps.LatLng(41.433696, 2.210198)*/}
            {/*    )}*/}
            {/*    defaultOpacity={.5}*/}
            {/*    onClick={props.onClickGroundOverlay}*/}
            {/*    //onDblClick={props.onRightClickGroundOverlay}*/}
            {/*    // opacity={Math.max(0, 1 - ((map.zoom - 13) / 11) * 2)}*/}
            {/*    opacity={zoomLevel > 16 ? 0 : 1}*/}
            {/*/>*/}

            {/*<GroundOverlay*/}
            {/*    url={'https://bcnartmap.local:3001/artmap/groundoverlay--0_5.png'}*/}
            {/*    bounds={new window.google.maps.LatLngBounds(*/}
            {/*        new window.google.maps.LatLng(41.366574, 2.114874),*/}
            {/*        new window.google.maps.LatLng(41.433696, 2.210198)*/}
            {/*    )}*/}
            {/*    defaultOpacity={.5}*/}
            {/*    onClick={props.onClickGroundOverlay}*/}
            {/*    //onDblClick={props.onRightClickGroundOverlay}*/}
            {/*    // opacity={1 - Math.max(0, 1 - ((map.zoom - 13) / 11) * 2)}*/}
            {/*    opacity={zoomLevel > 16 ? 1 : 0}*/}
            {/*/>*/}

            <GroundOverlay
                url={'/groundoverlays/groundoverlay-' + props.filterEvent + '-' + drawScale + '.png'}
                // key={'go1-' + new Date()} // Force component remount each render
                key={'go1-' + props.filterEvent + '-' + drawScale} // Force component remount each render
                bounds={new window.google.maps.LatLngBounds(
                    // Scale = 1, margin more = 3x, margin / 2, with corrective offset
                    new window.google.maps.LatLng(41.365583884595, 2.1138838845952),
                    new window.google.maps.LatLng(41.435706119195, 2.2122081191954)
                )}
                defaultOpacity={1}
                onClick={props.onClickGroundOverlay}
                //onDblClick={props.onRightClickGroundOverlay}
                // opacity={Math.max(0, 1 - ((map.zoom - 13) / 11) * 2)}
                // opacity={zoomLevel > 16 ? 0 : 1}
            />

            {/*<GroundOverlay*/}
            {/*    url={'https://bcnartmap.local:3001/artmap/groundoverlay-' + props.filterEvent + '-0_5.png'}*/}
            {/*    bounds={new window.google.maps.LatLngBounds(*/}
            {/*        new window.google.maps.LatLng(41.366574, 2.114874),*/}
            {/*        new window.google.maps.LatLng(41.433696, 2.210198)*/}
            {/*    )}*/}
            {/*    key={'go2-' + new Date()} // Force component remount each render*/}
            {/*    defaultOpacity={.5}*/}
            {/*    onClick={props.onClickGroundOverlay}*/}
            {/*    //onDblClick={props.onRightClickGroundOverlay}*/}
            {/*    // opacity={1 - Math.max(0, 1 - ((map.zoom - 13) / 11) * 2)}*/}
            {/*    opacity={zoomLevel > 16 ? 1 : 0}*/}
            {/*/>*/}

            {markers}

            <Polyline path={props.pathCoordinates} geodesic={true} options={polylineStyle}></Polyline>
        </GoogleMap>
    </div>
}

export default React.memo(Map)
