import './App.css';
import React from "react";

function Cookies() {
    return (
        <div>
            <div className="cms-content-wrapper">
                <div className="section-wrapper">
                    <div className="text padding-spacing-nano">
                        <h2 style={{'margin-bottom': '50px', 'margin-top': "100px"}}>Política de Cookies</h2>
                        <h3>¿Qué es una cookie?</h3>
                        <p>Una cookie es un pequeño archivo de texto que los sitios web instalan en el ordenador o
                            dispositivo móvil de los usuarios que los visitan. Se utilizan generalmente para hacer que
                            los
                            sitios web funcionen, o lo hagan más eficientemente, así como para proporcionar información
                            a
                            los titulares de la web acerca de los hábitos de navegación de los usuarios, entre
                            otras.</p>
                        <p>Además de las cookies, pueden existir otras tecnologías con finalidades similares que
                            permiten
                            hacer seguimiento de la navegación del usuario por la web como son las web beacons, el local
                            storage del navegador o las etiquetas de pixel.</p>
                        <h3>¿Que tipo de cookies utilizamos?</h3>
                        <p>Según la entidad que las gestione, las cookies pueden ser:</p>
                        <ul>
                            <li>
                                <p>Cookies propias: Son aquellas que han sido creadas o son gestionadas por Artmapi.</p>
                            </li>
                            <li>
                                <p>Cookies de terceros: Son aquellas que han sido creadas y son administradas por
                                    terceros que prestan un servicio a Artmapi. En el caso que se acepten las cookies
                                    de terceros, podrán eliminarse desde las opciones del navegador o desde el sistema que
                                    ofrezca el propio tercero. Pueden consultarse las cookies de terceros en la sección
                                    “Configuración de cookies” al pie de página. </p>
                            </li>
                        </ul>
                        <p>Según el plazo de tiempo que permanecen activadas, las cookies pueden ser:</p>
                        <ul>
                            <li>
                                <p>Cookies de sesión: Son aquellas cookies que, como su propio nombre indica, se
                                    instalan en
                                    el terminal del usuario durante la navegación por el sitio web hasta que finaliza la
                                    sesión.</p>
                            </li>
                            <li>
                                <p>Cookies persistentes: Son aquellas cookies que finalizada la sesión del usuario se
                                    mantienen instaladas en el terminal del usuario por un periodo de tiempo
                                    determinado. </p>
                            </li>
                        </ul>
                        <p>Según las finalidades con las que se utilicen, las cookies pueden ser: </p>
                        <ul>
                            <li>
                                <p>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través de una
                                    página web, plataforma o aplicación y la utilización de las diferentes opciones o
                                    servicios que en ella existan, incluyendo aquellas que el editor utiliza para
                                    permitir
                                    la gestión y operativa de la página web y habilitar sus funciones y servicios.</p>
                            </li>
                            <li>
                                <p>Cookies analíticas: Son aquellas que permiten al responsable de las mismas el
                                    seguimiento
                                    y análisis del comportamiento de los usuarios de los sitios web a los que están
                                    vinculados. La información recogida se utiliza en la medición de la actividad de los
                                    sitios web, con el fin de introducir mejoras en función del análisis de los datos
                                    del
                                    uso que hacen los usuarios del servicio.</p>
                            </li>
                            <li>
                                <p>Cookies publicitarias comportamentales: Son aquellas que almacenan información del
                                    comportamiento de los usuarios obtenida a través de la observación continuada de sus
                                    hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar
                                    publicidad en función del mismo. </p>
                            </li>
                        </ul>
                        <p>En la siguiente tabla se listan las cookies que utilizamos en este sitio web, con qué
                            finalidades
                            y durante cuánto tiempo: </p>
                        <div className="bt-wrapper">
                            <table data-bt-active="true" className={'w-full'}>
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Función</th>
                                    <th>Titular</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td data-th="Nombre"><span className="bt-content">_ga</span></td>
                                    <td data-th="Función"><span className="bt-content">analytics</span></td>
                                    <td data-th="Titular"><span className="bt-content">artmapi.com</span></td>
                                </tr>
                                <tr>
                                    <td data-th="Nombre"><span className="bt-content">_ga_SXK2Q5LZDC</span></td>
                                    <td data-th="Función"><span className="bt-content">analytics</span></td>
                                    <td data-th="Titular"><span className="bt-content">artmapi.com</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>Puedes cambiar tus preferencias de cookies en cualquier momento haciendo clic en el botón
                            "Configuración de cookies" al pie de página. Es posible que tengas que actualizar la página
                            para
                            que la configuración surta efecto.</p>
                        <h3>¿Cómo deshabilitar las cookies?</h3>
                        <p>La mayoría de los navegadores web permiten cierto control de la mayoría de las cookies a
                            través
                            de la configuración del navegador. Puedes configurar las cookies en los navegadores más
                            comunes
                            siguiendo sus instrucciones:</p>
                        <ul>
                            <li><a
                                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=es">Google
                                Chrome</a></li>
                            <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft
                                Edge</a></li>
                            <li><a href="https://support.mozilla.org/es/kb/Borrar%20cookies">Mozilla Firefox</a></li>
                            <li><a
                                href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft
                                Internet Explorer</a></li>
                            <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a></li>
                            <li><a href="https://support.apple.com/es-es/HT201265">Apple Safari</a></li>
                        </ul>
                        <p>Para encontrar información relacionada con otros navegadores, puedes visitar el sitio web del
                            navegador correspondiente.</p>
                        <p>Para no ser rastreado por Google Analytics en todos los sitios web, visite <a
                            href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
                        </p>
                        <h3>Transferencias internacionales de datos</h3>
                        <p>Existe la posibilidad de que los terceros identificados en esta Política de Cookies realicen
                            transferencias internacionales de datos sin el nivel de adecuación o de garantías
                            apropiadas.</p>
                        <p>Puedes obtener información acerca de las transferencias a terceros países que, en su caso,
                            realizan los terceros identificados en esta Política de Cookies en sus correspondientes
                            políticas.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cookies;
