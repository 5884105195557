import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useDraggable} from '@dnd-kit/core';
import { Trans } from 'react-i18next';

export function RouteItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    // const {attributes, listeners, setNodeRef, transform, transition} = useDraggable({
    //     id: 'draggable-' + props.id
    // });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    let introText;

    switch (props.index) {
        case 1:
            introText = 'Going to ';
            break;
        case props.lastIndex:
            introText = 'And finishing at ';
            break;
        default:
            introText = 'Then to ';
    }

    return <div ref={setNodeRef} style={style} className="route-item user-select-none">
        <div className="content">
            <div className="handle" {...listeners} {...attributes}>
            </div>
            <div className="inline-block">
                <strong className="route-item-number">{props.index}</strong>
            </div>
            <div className="inline-block select-none cursor-pointer" onClick={() => props.showInfoPanel(props.object.coos)}>
                <img src={props.object.image} className="mb-1 w-100px float-left mr-3 rounded" alt="Gallery"></img>
                <button className="hover:underline font-bold">{props.object.title}</button>
                <br/>
                <div className="text-sm">{props.object.schedule}</div>
            </div>
            {/*<div className="close-button-wrapper">*/}
            {/*    <div className="close-button close-button--top-27">×</div>*/}
            {/*</div>*/}
        </div>
    </div>
}
