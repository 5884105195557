import {Component} from 'react'
import React from 'react'
import {Circle, InfoWindow, Marker} from "@react-google-maps/api"
import Globals from "./Globals";

class LocationMarker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            location: {lat: -41.39474690000001, lng: 2.172483800000009},
            scale: 6,
            accuracy: 1
        }
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            // if(!Globals.debugMode) {
                let thisRef = this

                console.log("GPS Available");
                navigator.geolocation.watchPosition((pos) => thisRef.updateGeoPosition(pos),
                    function () {
                        console.log('unable to update geo location')
                    }, {enableHighAccuracy: true})
                navigator.geolocation.getCurrentPosition((pos) => thisRef.updateGeoPosition(pos))
            // }
        } else {
            console.log("GPS Not Available");
        }
    }

    componentWillUnmount() {
    }

    updateGeoPosition(position) {
        console.log(position)
        console.log("Latitude is :", position.coords.latitude)
        console.log("Longitude is :", position.coords.longitude)
        console.log('Accuracy is : ', position.coords.accuracy)

        // Only set GPS location if in bounds
        if(position.coords.latitude < 41.366574 - 0.09
            || position.coords.latitude > 41.433696 + 0.09
            || position.coords.longitude < 2.114874 - 0.09
            || position.coords.longitude > 2.210198 + 0.09) {
            console.log('Out of bounds, not settings GPS location')
            return;
        }

        console.log('In bounds: ' + JSON.stringify(position.coords))

        let newLocation = {...this.state.location}
        newLocation.lat = position.coords.latitude
        newLocation.lng = position.coords.longitude

        this.setState({location: newLocation})
        // thisRef.setState({scale: thisRef.getScale(thisRef.state.location.lat, 10 + 1)})

        this.setState({accuracy: Math.round(position.coords.accuracy * 100) / 100})
    }

    getScale = (lat, zoom) => {
        return 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom)
    }

    render() {
        if (this.state.accuracy !== -1) {
            let locationText = <div>Mi GPS {this.state.accuracy < 100 ? '' : '(impreciso)'} (~{this.state.accuracy}m)</div>

            return (
                <div>
                    <Marker
                        position={this.state.location}
                        icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: this.state.scale,
                            fillColor: "#00ff00",
                            fillOpacity: 0.4,
                            strokeColor: "#00ff00",
                            strokeOpacity: 1,
                            strokeWeight: 1
                        }}
                        key={this.props.key2}
                        {...this.props}
                    />

                    {/*<InfoWindow pixelOffset={"20"} position={this.state.location}>*/}
                    {/*    <div className="map-label">*/}
                    {/*        {locationText}*/}
                    {/*    </div>*/}
                    {/*</InfoWindow>*/}
                </div>
            )
        }
        else {
            return null;
        }
    }
}

export default LocationMarker;
